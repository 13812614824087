<template>
<div class="usage-iframe-wrapper">
  <iframe v-if="token" class="usage-iframe" width="100%" height="100%" :src="`${iframeUrl}?token=${token}`" title="description"></iframe>
  <organization-report-modal ref="organization-report-modal" :dinamicOrg="selectedOrg" :transmissions="orgTransmissions"/>
</div>
</template>

<script lang="ts">
import OrganizationReportModal from '@/views/pages/middleware/MiddlewareManagement/Components/Modals/OrganizationReportModal.vue'

export default {
  components: {
    OrganizationReportModal,
  },
  data() {
    return {
      iframeUrl: process.env.VUE_APP__ORG_DASHBOARD_IFRAME,
      token: undefined,
      selectedOrg: -1,
      orgTransmissions: undefined,
    }
  },
  mounted () {
    this.token = localStorage.getItem("sessionKey")
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    async receiveMessage(msg) {
      if (msg.origin !== this.iframeUrl){
        return
      }

      // if (fa)
      const orgId = msg.data
      this.$refs['organization-report-modal'].show()
      this.getTransmissionByOrganization(orgId)
    },
    getTransmissionByOrganization(id){
      this.orgTransmissions = undefined
      this.selectedOrg = id
      const payload  = {
        organization: id,
        filter:{
          order: 'id DESC',
          per_page: 50, 
          page: 1
        }
      }
      return this.$store.dispatch('getTransmissionByOrganization', payload)
        .then((resp)=>{
          this.orgTransmissions = resp
        })
    }

  },
}
</script>

<style lang="scss" scoped>
.usage-iframe-wrapper{
  height: 82vh;
}
.usage-iframe{
  border: none;
}
</style>

